
import { useEffect, useState } from 'react';
import './App.css';


const TopBar = () => {

  return <div style={{
    position: "fixed",
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  }}>
    <a href="#section1" style={{
      width: "30%"
    }}>
      <img loading="eager" alt={"section1"} src="/images/1.png" style={{
        width: "100%"
      }} />
    </a>
    <a href="#section3" style={{
      width: "30%"
    }}>
      <img loading="eager" alt={"section3"} src="/images/3.png" style={{
        width: "100%"
      }} />
    </a>
    {/* <a href="#section4" style={{
      width: "30%"
    }}>
      <img loading="eager" alt={"section4"} src="/images/6-15.png" style={{
        width: "100%"
      }} />
    </a> */}

    <a href="#section5" style={{
      width: "30%"
    }}>
      <img loading="eager" alt={"section5"} src="/images/16-24.png" style={{
        width: "100%"
      }} />
    </a>

  </div>
}

const BottomBar = () => {

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    position: "fixed",
    bottom: 10,
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    }}>

      <a href="#section6" style={{
        width: "30%"
      }}>
        <img loading="eager" alt={"section6"} src="/images/21.png" style={{
          width: "100%"
        }} />
      </a>

      <a href="#section7" style={{
        width: "30%"
      }}>
        <img loading="eager" alt={"section7"} src="/images/24.png" style={{
          width: "100%"
        }} />
      </a>

      <a href="#section8" style={{
        width: "30%",

        // display : 'flex',
        // justifyContent : 'center'
      }}>
        <img loading="eager" alt={"section8"} src="/images/25.png" style={{
          width: "100%"
        }} />
      </a>

    </div>

  </div>
}


function App() {

  const section = [
    {
      section: "section1",
      images: ["/images/MBC_1.png"]
    },
    {
      section: "section3",
      images: [
        "/images/MBC_3.png",
        "/images/MBC_4.png",
        "/images/MBC_5.png",
      ]
    },
    // {
    //   section: "section4",
    //   images: [
    //     "/images/section-4-1.png",
    //     "/images/section-4-2.png",
    //     "/images/section-4-3.png",
    //     "/images/section-4-4.png",
    //     "/images/section-4-5.png",
    //     "/images/section-4-6.png",
    //     "/images/section-4-7.png",
    //     "/images/section-4-8.png",
    //   ]
    // },
    {
      section: "section5",
      images: [
        "/images/100.png",
        "/images/101.png",
        "/images/102.png",
        "/images/103.png",
        "/images/104.png",
        "/images/105.png",
        "/images/106.png",
        "/images/107.png",
        "/images/108.png",
        "/images/109.png",
        "/images/110.png",
      ]
    },
    {
      section: "section6",
      images: [
        "/images/MBC_25.png",
        "/images/MBC_26.png"
      ]
    },
    {
      section: "section7",
      images: [
        "/images/MBC_27.png"
      ]
    },
    {
      section: "section8",
      images: [
        "/images/MBC_28.png",
        "/images/MBC_29.png",
        "/images/MBC_30.png",
        "/images/MBC_31.png",
      ]
    },
  ]

  const [Loading, setLoading] = useState(true)

  useEffect(() => {

    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [])

  return (
    <div className="App" style={{
      width: "100%"
    }}>
      {section.map((sec, index) => {
        return <div id={sec.section} key={index}>
          {sec.images.map((img, key) => <img loading="lazy" alt={sec.section} key={key} className="img-fluid" src={img} />)}
        </div>
      })}


      <TopBar />
      <BottomBar />
      {Loading ? <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        backgroundColor: "#223974",
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'

      }}>
        <div class="loader">Loading...</div>
      </div> : null}
      <style>
        {`
          .loader,
          .loader:before,
          .loader:after {
            border-radius: 50%;
          }
          .loader {
            color: #ffffff;
            font-size: 11px;
            text-indent: -99999em;
            margin: 55px auto;
            position: relative;
            width: 10em;
            height: 10em;
            box-shadow: inset 0 0 0 1em;
            -webkit-transform: translateZ(0);
            -ms-transform: translateZ(0);
            transform: translateZ(0);
          }
          .loader:before,
          .loader:after {
            position: absolute;
            content: '';
          }
          .loader:before {
            width: 5.2em;
            height: 10.2em;
            background: #ffffff;
            border-radius: 10.2em 0 0 10.2em;
            top: -0.1em;
            left: -0.1em;
            -webkit-transform-origin: 5.1em 5.1em;
            transform-origin: 5.1em 5.1em;
            -webkit-animation: load2 2s infinite ease 1.5s;
            animation: load2 2s infinite ease 1.5s;
          }
          .loader:after {
            width: 5.2em;
            height: 10.2em;
            background: #ffffff;
            border-radius: 0 10.2em 10.2em 0;
            top: -0.1em;
            left: 4.9em;
            -webkit-transform-origin: 0.1em 5.1em;
            transform-origin: 0.1em 5.1em;
            -webkit-animation: load2 2s infinite ease;
            animation: load2 2s infinite ease;
          }
          @-webkit-keyframes load2 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
          @keyframes load2 {
            0% {
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
}

export default App;
